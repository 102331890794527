import { CompositeProduct } from "types";

export const getProductsByStatus = (
	products: CompositeProduct[],
): { verified: CompositeProduct[]; unverified: CompositeProduct[] } => {
	const verified = products?.filter((item) => item.__typename !== "Unverified");
	const unverified = products?.filter((item) => item.__typename === "Unverified");

	return { verified, unverified };
};
